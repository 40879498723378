import { Routes, Route } from "react-router-dom";
import { DefaultLayout } from "../layouts";

import { HomePage } from "../pages/Home";
import { Login } from "../pages/Login";
import { Statements } from "../pages/Statements";
import { Invoices } from "../pages/Invoices";


import { Payments } from "../pages/Payments";
import { NewPayment } from "../pages/NewPayment";
import { PaymentDetail } from "../pages/PaymentDetail";
import { EditPayment } from "../pages/EditPayment";



import { OrdersPage } from "../pages/Orders";
import { NewOrder } from "../pages/NewOrder";
import { EditOrder } from "../pages/EditOrder";
import { OrderDetail } from "../pages/OrderDetail";

import { Franchises } from "../pages/Franchises";


import { NewCreditNote } from "../pages/NewCreditNote";
import { CreditNoteDetail } from "../pages/CreditNoteDetail";
import { EditCreditNote } from "../pages/EditCreditNote";

import { CustomerDetail } from "../pages/CustomerDetail";
import { Customers } from "../pages/Customers";
import { NewCustomer } from "../pages/NewCustomer";
import { EditCustomer } from "../pages/EditCustomer";
import { CustomerOrderHistorySearch } from "../pages/CustomerOrderHistorySearch";


import { NewOrderV2 } from "../pages/orders/v2/New";
import { EditOrderV2 } from "../pages/orders/v2/Edit";

import { QuotationsPage } from "../pages/Quotations";
import { QuotationDetail } from "../pages/QuotationDetails";
import { EditQuotation } from "../pages/EditQuotation";
import { NewQuotation } from "../pages/NewQuotation";


const useDefaultLayout = (page) => <DefaultLayout>{page}</DefaultLayout>;

export const RoutesTree = () => (
  <Routes>
    <Route path="/" element={useDefaultLayout(<HomePage />)} />
    <Route path="login" element={<Login />} />

    <Route path="payments">
      <Route path="new" element={useDefaultLayout(<NewPayment />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<PaymentDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditPayment />)} />
      </Route>
      <Route index element={useDefaultLayout(<Payments />)} />
    </Route>


    <Route path="orders">
      <Route path="new" element={useDefaultLayout(<NewOrder />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<OrderDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditOrder />)} />
      </Route>
      <Route index element={useDefaultLayout(<OrdersPage />)} />
    </Route>

    <Route path="orders">
      <Route path="new" element={useDefaultLayout(<NewOrder />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<OrderDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditOrder />)} />
      </Route>
      <Route index element={useDefaultLayout(<OrdersPage />)} />
      <Route path="v2">
        <Route path="new" element={useDefaultLayout(<NewOrderV2 />)} />
        <Route path=":id">
          <Route path="edit" element={useDefaultLayout(<EditOrderV2 />)} />
        </Route>
      </Route>
      <Route index element={useDefaultLayout(<OrdersPage />)} />
    </Route>


    <Route path="quotations">
      <Route index element={useDefaultLayout(<QuotationsPage />)} />
      <Route path="new" element={useDefaultLayout(<NewQuotation />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<QuotationDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditQuotation />)} />
      </Route>
    </Route>

    <Route path="customers">
      <Route path="new" element={useDefaultLayout(<NewCustomer />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<CustomerDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditCustomer />)} />
        <Route path="history" element={useDefaultLayout(<CustomerOrderHistorySearch />)} />
      </Route>
      <Route index element={useDefaultLayout(<Customers />)} />
    </Route>



    <Route path="credit-notes">
      <Route path="new" element={useDefaultLayout(<NewCreditNote />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<CreditNoteDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditCreditNote />)} />
      </Route>
    </Route>


    <Route path="statements" element={useDefaultLayout(<Statements />)} />
    <Route path="franchises" element={useDefaultLayout(<Franchises />)} />
    <Route path="invoices" element={useDefaultLayout(<Invoices />)} />

 
  </Routes>
);
