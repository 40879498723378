import { useState, useEffect } from "react";
import { Button, Table, Input, Select, Space } from "antd";
import { useQuery } from "@apollo/client";
import { MoneyCell } from "../tablecells";
import { CustomersQuery } from "../../graphql/customers.graphql";
import { PageContentContainer } from "../atoms/PageContentContainer";
import { VendorsMap } from "../../constants";
import { SyncOutlined } from "@ant-design/icons";
import { ToolbarWrapper } from "../atoms/ToolbarWrapper";

export const CustomerSeletionTable = ({ onCustomerSelect }) => {
  const [filters, setFilters] = useState({ isFranchise: { equals: false } });
  const { data, loading, refetch } = useQuery(CustomersQuery, {
    variables: {
      filters: filters,
    },
    fetchPolicy: "network-only",
  });
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (data?.customers) {
      setCustomers(data.customers);
    }
  }, [data, loading]);

  const columns = [
    { title: "#", dataIndex: "customerCode", key: "customerCode" },
    { title: "Customer", dataIndex: "name", key: "name" },
    {
      title: "Salesperson",
      dataIndex: ["salesperson", "name"],
      key: "salesperson",
    },

    {
      title: "Franchise",
      dataIndex: ["franchise", "name"],
      key: "franchise",
    },
    {
      title: "Vendor",
      dataIndex: "invoiceTemplate",
      key: "vendor",
      render: (value) => VendorsMap[value].label,
    },
    {
      title: "Account balance",
      dataIndex: "accountBalance",
      key: "accountBalance",
      ...MoneyCell,
    },
    {
      title: "Select",
      dataIndex: "Select",
      key: "select",
      render: (_, record) => (
        <Button type="primary" onClick={() => onCustomerSelect(record)}>
          Select
        </Button>
      ),
    },
  ];

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  const handleSearch = (value) => {
    const customerfilter = { contains: value, mode: "insensitive" };
    setFilters((f) => ({ ...f, OR: [{ name: customerfilter }, { customerCode: customerfilter }] }));
  };

  const handleFilter = (value) => {
    if (value === "ALL") {
      const currentFilters = { ...filters };
      delete currentFilters.invoiceTemplate;
      console.log("currentFilters: ", currentFilters);
      setFilters(currentFilters);
    } else {
      setFilters((f) => ({ ...f, invoiceTemplate: { equals: value } }));
    }
  };

  return (
    <PageContentContainer>
      <ToolbarWrapper>
        <Space>
          <Input.Search
            placeholder="Search name or code"
            enterButton
            allowClear
            onSearch={handleSearch}
            style={{ width: 320 }}
          />
          <Space>
            <label>Vendor:</label>
            <Select
              defaultValue="ALL"
              style={{ width: 120 }}
              onChange={handleFilter}
              options={[
                {
                  value: "ALL",
                  label: "All",
                },
                {
                  value: "NEWNORTH",
                  label: "Newnorth",
                },
                {
                  value: "GLORY_FRESH",
                  label: "Glory Fresh",
                },
              ]}
            />
          </Space>
        </Space>
        <Space size={0}>
          <Button icon={<SyncOutlined />} onClick={handleRefresh}>
            Refresh
          </Button>
        </Space>
      </ToolbarWrapper>
      <Table
        dataSource={customers}
        columns={columns}
        rowKey="id"
        size="medium"
        loading={loading}
        pagination={false}
      />
    </PageContentContainer>
  );
};
