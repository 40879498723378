import { useState } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { Input, Button, Form, DatePicker, Select, Space, notification, Modal, Spin } from "antd";

import { OrdersQuery } from "../../graphql/orders.graphql";
import { useNavigate } from "react-router-dom";
import {
  CreatePaymentBatchMutation,
  PaymentBatchesQuery,
} from "../../graphql/paymentBatches.graphql";
import { CreatePaymentsMutation } from "../../graphql/payments.graphql";
import axios from "axios";

const paymentOptions = [
  { label: "Cash", value: "cash" },
  { label: "EFT", value: "eft" },
  { label: "Credit Card", value: "card" },
  { label: "Shopify", value: "shopify" },
  { label: "POS", value: "pos" },
  { label: "Other", value: "other" },
];

export const PaymentSubmitForm = ({
  activeCustomer,
  selectedOrder,
  dataSource,
  totalApplied,
  discountAmount,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalMsg, setModalMsg] = useState("Creating payment, please wait...");
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    const dateString = form.getFieldValue("date").toISOString() || moment().toISOString();

    const paymentMethod = form.getFieldValue("paymentMethod");
    const totalAmount = Number(totalApplied) + Number(discountAmount);
    const batchNotes = form.getFieldValue("notes") || "";
    let paymentsList = [];
    selectedOrder.forEach((item) => {
      paymentsList.push({
        name: "Payment",
        receivedDate: dateString,
        notes: "Customer payment",
        orderId: item.id,
        amount: String(dataSource[item.index].amountApplied),
        paymentMethod: paymentMethod,
      });
      if (dataSource[item.index].discount && Number(dataSource[item.index].discount) !== 0) {
        paymentsList.push({
          name: "Adjustment",
          receivedDate: dateString,
          notes: "Manual discount",
          orderId: item.id,
          amount: String(dataSource[item.index].discount),
          paymentMethod: paymentMethod,
        });
      }
    });
    const data = {
      paymentType: "Payment Batch",
      receivedDate: dateString,
      paymentMethod: paymentMethod,
      notes: batchNotes,
      amount: String(totalAmount),
      discountAmount: discountAmount,
      receivedAmount: totalApplied,
      customerId: activeCustomer.id,
      payments: paymentsList,
    };

    const res = await axios.post(`${process.env.REACT_APP_REST_API_ENDPOINT}/payments`, data);
    if (res.data) {
      navigate("/payments");
    }
  };

  return (
    <>
      <Modal visible={loading} footer={null}>
        <Spin />
        <p>{modalMsg}</p>
        <p>Please do not close the tab.</p>
      </Modal>
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
        initialValues={{
          date: moment(),
          paymentMethod: "eft",
        }}
      >
        <Space>
          <Form.Item label="Date:" name="date" style={{ marginRight: "30px" }}>
            <DatePicker />
          </Form.Item>
          <Form.Item label="Payment Method:" name="paymentMethod" style={{ marginRight: "30px" }}>
            <Select style={{ width: 150 }}>
              {paymentOptions.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Notes:" name="notes">
            <Input.TextArea
              rows={2}
              placeholder="Received by..."
              style={{ width: "350px", marginBottom: "-20px" }}
            />
          </Form.Item>
        </Space>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            disabled={!!!selectedOrder[0]}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
