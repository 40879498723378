import { Space, Avatar, Typography, Card } from "antd";
import { VendorInfo } from "../atoms/VendorInfo";
export const CustomerDetailCard = ({ customer = { name: "" }, footer, extra }) => {
  const { name, email, phone, billingAddress, quotations } = customer;
  console.log("quotations: ", quotations);
  return (
    <Card title="Selected customer" actions={[footer]} extra={extra}>
      <Space direction="vertical">
        <Space>
          <Avatar>{customer.name?.charAt(0).toUpperCase()}</Avatar>
          <Typography.Title level={5}>{name}</Typography.Title>
        </Space>
        <Typography.Text>Email: {email}</Typography.Text>
        <Typography.Text>Phone: {phone}</Typography.Text>
        <Typography.Text>Billing Address: {billingAddress}</Typography.Text>
        <Typography.Text>
          Fixed Margin:{" "}
          {customer?.fixedMargin ? `${(customer?.fixedMargin * 100).toFixed(2)}%` : "n/a"}
        </Typography.Text>
        <VendorInfo vendor={customer.invoiceTemplate} />
      </Space>
    </Card>
  );
};
