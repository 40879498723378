import { View, Image } from "@react-pdf/renderer";
import { Stack, Typography } from "./utilities";

export const VendorHeader = () => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      width: "50%",
      alignItems: "flex-start",
    }}
  >
    <Image
      style={{
        height: "50px",
        width: "auto",
        objectFit: "cover",
        marginRight: 15,
      }}
      alt="logo"
      src={`${process.env.PUBLIC_URL}/images/newnorth.jpg`}
    />
    <View>
      <Typography>Warehouse 3, Building J, Sydney Market</Typography>
      <Typography>Flemington NSW 2129</Typography>
      <Typography>Tel: 0400 628 618, (02)93256061</Typography>
      <Typography>Fax: (02)93256062</Typography>
      <Typography></Typography>
      <Typography> ABN: 78 607 978 923</Typography>
    </View>
  </View>
);

export const VendorHeaderGlory = () => {
  return (
    <Stack
      sx={{
        width: "50%",
        alignItems: "flex-end",
      }}
    >
      <Image
        style={{
          height: "auto",
          width: "80%",
          objectFit: "cover",
          marginRight: 15,
        }}
        alt="logo"
        src={`${process.env.PUBLIC_URL}/images/glory.png`}
      />
    </Stack>
  );
};
