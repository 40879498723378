import { useState, useEffect } from "react";
import {
  Table,
  Space,
  PageHeader,
  Button,
  Tag,
  Typography,
  notification,
  Menu,
  Dropdown,
} from "antd";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { ROUTES } from "../constants";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { CustomerCell, OrdersActionsCell } from "../components/tablecells";
import { StatusTag } from "../components/tags/StatusTag";
import { usePDF } from "@react-pdf/renderer";
import moment from "moment";
import { ResourceToolbar } from "../components/ResourceToolbar";
import { PrinterFilled } from "@ant-design/icons";
import { OrdersReportPDF } from "../components/pdf/OrdersReportPDF";
import { PageContainer } from "../components/atoms/PageContainer";
import { OrdersQuery, OrdersSummaryReportQuery } from "../graphql/orders.graphql";
import { getTodayTomorrowTag, getFulfillmentTypeTag, formatMoney } from "../utils";

const columns = [
  {
    title: "#",
    dataIndex: "name",
    key: "name",
    render: (orderNumber, record) => {
      return (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          <Link to={`/orders/${record.id}`}>{orderNumber}</Link>
          {getFulfillmentTypeTag(record.fulfillmentType)}
        </Typography.Title>
      );
    },
  },

  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
    render: (orderDate) => {
      const { dateStr, tag } = getTodayTomorrowTag(orderDate);
      return (
        <Space>
          {dateStr} {tag && <Tag color={tag.color}>{tag.text}</Tag>}
        </Space>
      );
    },
  },

  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    render: (_, record) => <CustomerCell orderId={record.id} customer={record.customer || {}} />,
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => <StatusTag status={text} />,
  },

  {
    title: "Fulfillment",
    dataIndex: "fulfillmentStatus",
    key: "fulfillmentStatus",
    render: (text) => <StatusTag status={text} />,
  },

  {
    title: "No. of items",
    dataIndex: "lineItemsCount",
    key: "lineItemsCount",
    render: (count) => `${count} items`,
  },

  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "right",
    render: (total, record) =>
      record.status === "draft" ? <StatusTag status="pending" /> : formatMoney(total),
  },

  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "right",
    render: (_, record) => {
      return <OrdersActionsCell order={record} />;
    },
  },
];
const pageSize = 20;
const today = moment().format("DD/MM/YYYY");

export const OrdersPage = () => {
  const [dateRange, setDateRange] = useState(`${today} -`);
  const [rangeString, setRangeString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [ordersSummary, setOrdersSummary] = useState([]);
  const [ready, setReady] = useState(false);

  const [filters, setFilters] = useState({
    isCancelled: { equals: false },
    isCreditNote: { equals: false },
    orderDate: { gte: moment().startOf("day").toISOString() },
  });
  const { data, loading, refetch, networkStatus } = useQuery(OrdersQuery, {
    variables: { take: pageSize, skip: (currentPage - 1) * pageSize, where: filters },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [fetchAllOrders, { loading: ordersSummaryLoading }] =
    useLazyQuery(OrdersSummaryReportQuery);
  const [reportPdfInstance, updateReportPdfInstance] = usePDF({
    document: (
      <OrdersReportPDF
        orders={ordersSummary}
        date={rangeString}
        grandTotal={ordersSummary
          .reduce((total, current) => total + parseFloat(current.total), 0)
          .toFixed(2)}
      />
    ),
  });

  useEffect(() => {
    if (data?.orders) {
      setOrders(data.orders);
    }
  }, [data, loading]);

  useEffect(() => {
    if (ordersSummary.length > 0) {
      updateReportPdfInstance();
      setReady(true);
    }
    // eslint-disable-next-line
  }, [ordersSummary]);

  useEffect(() => {
    if (ready && reportPdfInstance && !reportPdfInstance.error && !reportPdfInstance.loading) {
      window.open(reportPdfInstance.url, "_blank").focus();
      setReady(false);
    }
  }, [ready, reportPdfInstance]);

  const handleRefresh = () => {
    refetch();
  };

  const handleSearch = (value) => {
    const customerfilter = { name: { contains: value, mode: "insensitive" } };
    setFilters((f) => ({
      ...f,
      OR: [{ name: { contains: value, mode: "insensitive" } }, { customer: customerfilter }],
    }));
  };

  const handleRangeChange = (range) => {
    if (!range) {
      const fil = { ...filters };
      delete fil.orderDate;
      setFilters(fil);
      setDateRange("All");
      return;
    }

    const [start, end] = range;

    if (!start) {
      setDateRange(`- ${end.format("DD/MM/YYYY")}`);
      setFilters((f) => ({
        ...f,
        orderDate: {
          lte: end.endOf("day").toISOString(),
        },
      }));
      return;
    }
    if (!end) {
      setDateRange(`${start.format("DD/MM/YYYY")} -`);
      setFilters((f) => ({
        ...f,
        orderDate: {
          gte: start.startOf("day").toISOString(),
        },
      }));
      return;
    }

    setDateRange(`${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}`);
    setFilters((f) => ({
      ...f,
      orderDate: {
        gte: start.startOf("day").toISOString(),
        lte: end.endOf("day").toISOString(),
      },
    }));
  };

  const handlePrint = async (date) => {
    if (date) setRangeString(`${today} - ${today}`);
    let where = {
      isCancelled: { equals: false },
      isCreditNote: { equals: false },
      orderDate: {
        gte: moment().startOf("day").toISOString(),
        lte: moment().endOf("day").toISOString(),
      },
    };

    if (!date) {
      setRangeString(dateRange);
      console.log("dateRange: ", dateRange);
      if (dateRange === "All") {
        delete where.orderDate;
      } else {
        const [start, end] = dateRange.split("-");
        if (!start) where.orderDate = { lte: moment(end, "DD/MM/YYYY").endOf("day").toISOString() };
        else if (!end)
          where.orderDate = { gte: moment(start, "DD/MM/YYYY").startOf("day").toISOString() };
        else
          where.orderDate = {
            gte: moment(start, "DD/MM/YYYY").startOf("day").toISOString(),
            lte: moment(end, "DD/MM/YYYY").endOf("day").toISOString(),
          };
      }
    }

    const res = await fetchAllOrders({
      variables: {
        where: where,
      },
      fetchPolicy: "network-only",
    });
    if (res.data.orders.length > 0) {
      console.log("res.data.orders.length: ", res.data.orders.length);
      setOrdersSummary(res.data.orders);
    } else {
      notification.warning({ message: `No orders ${date ? "today" : "in your search"}.` });
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "today",
          label: (
            <Button
              type="text"
              onClick={() => handlePrint("today")}
              loading={reportPdfInstance.loading || ordersSummaryLoading}
            >
              Print today's orders
            </Button>
          ),
        },
        {
          key: "dateRange",
          label: (
            <Button
              type="text"
              onClick={() => handlePrint()}
              loading={reportPdfInstance.loading || ordersSummaryLoading}
            >
              Print orders of your search
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title={moment().format("YYYY-MM-DD, dddd")}
        subTitle={`Showing ${orders.length} orders of ${data?.ordersCount}`}
        extra={[
          <Link key="pricing-analysis" to="/pricing-analysis">
            <Button type="text">Pricing analysis</Button>
          </Link>,
          <Dropdown overlay={menu} key="print-report">
            <Button icon={<PrinterFilled />}>Print</Button>
          </Dropdown>,
          <Link key="new-order-v2" to={ROUTES.ordersNewV2}>
            <Button type="primary">New order v2</Button>
          </Link>,
          // <Link key="new-order" to={ROUTES.ordersNew}>
          //   <Button type="primary">New order V1</Button>
          // </Link>,
        ]}
      />

      <PageContentContainer>
        <ResourceToolbar
          search={{ onSearch: handleSearch }}
          dateRange={{ onRangeChange: handleRangeChange }}
          onRefresh={handleRefresh}
        />

        <Table
          dataSource={orders}
          columns={columns}
          rowKey="id"
          size="medium"
          loading={loading || networkStatus}
          pagination={{
            total: data?.ordersCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
