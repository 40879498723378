import { useState, useEffect } from "react";
import { Table, Space, PageHeader, Button, Typography, Select, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ROUTES } from "../constants";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment";
import { VendorsMap } from "../constants";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { SortedCustomersQuery } from "../graphql/customers.graphql";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { PageContainer } from "../components/atoms/PageContainer";
import { CUSTOMER_SORT_OPTIONS } from "../constants";
import { SyncOutlined } from "@ant-design/icons";
import { SalespeopleQuery } from "../graphql/users.graphql";

const columns = [
  {
    title: "Customer",
    dataIndex: "name",
    key: "name",
    render: (customerName, record) => {
      return (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          <Link to={`/customers/${record.id}`}>{customerName}</Link>{" "}
        </Typography.Title>
      );
    },
  },
  {
    title: "Address",
    dataIndex: "deliveryAddress",
    key: "deliveryAddress",
  },
  {
    title: "No. of orders",
    dataIndex: "ordersCount",
    key: "ordersCount",
    render: (count, record) => `${count} orders`,
  },
  {
    title: "Last order",
    dataIndex: "lastOrder",
    key: "lastOrder",
    render: (_, record) => {
      if (record.orders.length > 0) {
        return moment(record.orders[0].orderDate).fromNow();
      }
    },
  },
  {
    title: "Vendor",
    dataIndex: "invoiceTemplate",
    key: "vendor",
    render: (value) => VendorsMap[value].label,
  },
  {
    title: "Salesperson",
    dataIndex: ["salesperson", "name"],
    key: "salesperson.name",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "right",
    render: (_, record) => {
      return (
        <Space>
          <Button>
            <Link to={`/customers/${record.id}/edit`}>
              Edit <EditOutlined />
            </Link>
          </Button>
        </Space>
      );
    },
  },
];

const pageSize = 50;
export const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [queryString, setQueryString] = useState("");
  const [salespeopleOptions, setSalespeopleOptions] = useState([{ label: "All", value: "ALL" }]);
  const [sortType, setSortType] = useState("lastOrderDesc");
  const [filters, setFilters] = useState({
    name: { contains: "", mode: "insensitive" },
    isFranchise: { equals: false },
  });

  const { data, loading, refetch, networkStatus } = useQuery(SortedCustomersQuery, {
    variables: {
      filters,
      sortType: sortType,
      take: pageSize,
      skip: (currentPage - 1) * pageSize,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const { data: salespeople, loading: salespeopleLoading } = useQuery(SalespeopleQuery);

  useEffect(() => {
    if (data?.sortedCustomers) {
      setCustomers(data?.sortedCustomers);
      setCustomersCount(data?.customersCount);
    }
  }, [data, loading]);

  useEffect(() => {
    if (salespeople?.users) {
      console.log("salespeople: ", salespeople);
      setSalespeopleOptions((options) => [
        ...options,
        ...salespeople.users.map((user) => ({ label: user.name, value: user.id })),
      ]);
    }
  }, [salespeople, salespeopleLoading]);

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  const handleSearch = (value) => {
    const customerfilter = { contains: value, mode: "insensitive" };
    setFilters((f) => ({ ...f, OR: [{ name: customerfilter }, { customerCode: customerfilter }] }));
    setCurrentPage(1);
  };

  const handleVendorFilterChange = (value) => {
    if (value === "ALL") {
      const currentFilters = { ...filters };
      delete currentFilters.invoiceTemplate;
      console.log("currentFilters: ", currentFilters);
      setFilters(currentFilters);
    } else {
      setFilters((f) => ({ ...f, invoiceTemplate: { equals: value } }));
    }
  };
  const handleSalespersonFilterChange = (value) => {
    if (value === "ALL") {
      const currentFilters = { ...filters };
      delete currentFilters.salesperson;
      setFilters(currentFilters);
    } else {
      setFilters((f) => ({ ...f, salesperson: { id: { equals: value } } }));
    }
  };

  const selectChange = (value) => {
    setSortType(value);
    setCurrentPage(1);
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Customers"
        subTitle={`Showing ${customers.length} customers of ${customersCount}`}
        extra={[
          <Select
            key="select"
            defaultValue={sortType}
            onChange={selectChange}
            options={CUSTOMER_SORT_OPTIONS}
            style={{
              width: 230,
            }}
          />,
          <Link key="new-customer" to={ROUTES.customersNew}>
            <Button type="primary">New customer</Button>
          </Link>,
        ]}
      />

      <PageContentContainer>
        <ToolbarWrapper>
          <Space>
            <Input.Search
              placeholder="Search name or code"
              enterButton
              allowClear
              onSearch={handleSearch}
              style={{ width: 320 }}
            />
            <Space>
              <label>Vendor:</label>
              <Select
                defaultValue="ALL"
                style={{ width: 120 }}
                onChange={handleVendorFilterChange}
                options={[
                  {
                    value: "ALL",
                    label: "All",
                  },
                  {
                    value: "NEWNORTH",
                    label: "Newnorth",
                  },
                  {
                    value: "GLORY_FRESH",
                    label: "Glory Fresh",
                  },
                ]}
              />
            </Space>
            <Space>
              <label>Salesperson:</label>
              <Select
                defaultValue="ALL"
                style={{ width: 120 }}
                onChange={handleSalespersonFilterChange}
                options={salespeopleOptions}
              />
            </Space>
          </Space>
          <Space size={0}>
            <Button icon={<SyncOutlined />} onClick={handleRefresh}>
              Refresh
            </Button>
          </Space>
        </ToolbarWrapper>{" "}
        <Table
          dataSource={customers}
          columns={columns}
          rowKey="id"
          size="medium"
          loading={loading || networkStatus}
          pagination={{
            current: currentPage,
            total: customersCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
